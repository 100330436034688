import ResponseResult from "@/types/classes/ResponseResult";
import { getNextJsApiURL } from "@/utils/api";
import {
  axiosDeleteRequestClientSide,
  axiosGetRequestClientSide,
  axiosPostRequestClientSide,
  axiosPutRequestClientSide,
} from "@/utils/axiosClientUtil";
import { getRandomSlug } from "@/utils/util";

interface NewContentElement {
  [key: string]: any;
  __new_id: string;
  attributes: Array<any>;
  __component: string;
}

export const getCmsPageRequest = async (
  url: string
): Promise<ResponseResult<any>> => {
  const pageResult: ResponseResult<any> = await axiosGetRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/pages/cms/find?url=${url}`)
  );
  return pageResult;
};

export const getDraftPageRequest = async (
  id: number
): Promise<ResponseResult<any>> => {
  const draftPageResult: ResponseResult<any> = await axiosGetRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/draftpages/cms/${id}`)
  );

  return draftPageResult;
};

export const updateDraftPageRequest = async (
  id: number,
  data: Array<any>
): Promise<ResponseResult<any>> => {
  const pageResult: ResponseResult<any> = await axiosPostRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/draftpages/update/${id}`),
    { id: id, content: data }
  );
  return pageResult;
};

export const publishPageRequest = async (
  pageId: number
): Promise<ResponseResult<any>> => {
  const publishResult: ResponseResult<any> = await axiosPostRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/pages/publish/${pageId}`),
    {}
  );
  return publishResult;
};

export const createPageRequest = async (
  data: any
): Promise<ResponseResult<any>> => {
  const publishResult: ResponseResult<any> = await axiosPostRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/pages/create`),
    data
  );
  return publishResult;
};

export const updatePageSettingsRequest = async (
  data: any
): Promise<ResponseResult<any>> => {
  return await axiosPostRequestClientSide(
    getNextJsApiURL("/cms/manage/pabu/pages/update"),
    data
  );
};

export const deletePageRequest = async (
  id: number
): Promise<ResponseResult<any>> => {
  return await axiosDeleteRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/pages/delete/${id}`)
  );
};

export const createPageCopyRequest = async (
  id: number,
  name: string,
  url: string
): Promise<ResponseResult<any>> => {
  const copyPageResult: ResponseResult<any> = await axiosPostRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/pages/create-copy-page/${id}`),
    { name, url }
  );
  return copyPageResult;
};

export const setDefaultPageRequest = async (
  id: number
): Promise<ResponseResult<any>> => {
  return await axiosPutRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/pages/set-default-page`),
    {
      id: id,
    }
  );
};

export const getAllPagesLocalized = async () => {
  const result = await axiosGetRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/pages`)
  );
  return result;
};

export const createEmptyContentElement = (
  element: any,
  integerDefaultNull?: boolean
) => {
  const newContentElement: NewContentElement = {
    __new_id: `new_${getRandomSlug()}`,
    attributes: element.attributes,
    __component: element.uid,
  };

  for (const attribute of Object.entries(element.attributes)) {
    const key = attribute[0];
    const value: any = attribute[1];
    if (!value.type) {
      newContentElement[key] = null;
      return;
    }
    switch (value.type) {
      case "string":
      case "text":
      case "richtext":
        newContentElement[key] = value.default ? value.default : "";
        break;
      case "json":
        if (typeof value.default === "object") {
          newContentElement[key] = value.default;
        } else {
          newContentElement[key] = value.default
            ? JSON.parse(value.default)
            : null;
        }
        break;
      case "boolean":
        newContentElement[key] = value.default ? value.default : false;
        break;
      case "integer":
        if (integerDefaultNull) {
          // this is needed for formsBuilder in forms builder
          // fields like cfgFieldCharactersMin/Max would be 0 otherwise
          newContentElement[key] = value.default ? value.default : null;
        } else {
          newContentElement[key] = value.default ? value.default : 0;
        }
        break;
      case "enumeration":
        const firstEnumVal =
          value.enum && value.enum.length > 0 ? value.enum[0] : null;
        newContentElement[key] = value.default ? value.default : firstEnumVal;
        break;
      case "media":
        newContentElement[key] = null;
        break;
      case "component":
        newContentElement[key] = [];
      default:
        break;
    }
  }
  return newContentElement;
};

export const createEmptyNestedContentElement = (element: any) => {
  const newContentElement = createEmptyContentElement(
    element
  ) as Partial<NewContentElement>;
  delete newContentElement?.__component;
  return newContentElement;
};
